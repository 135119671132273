import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import STable from '@surely-vue/table'
import router from './router'
import store from './store'
import '@/router/permission'
import 'ant-design-vue/dist/antd.less'
import '@surely-vue/table/dist/index.css'

const app = createApp(App).use(router).use(store).use(Antd).use(STable)
app.mount('#app')
