
import { defineComponent, h } from 'vue'
import { RocketTwoTone } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { Dot } from '@/views/mixins/myListMixin.js';
// import data from 'china-area-data'

export default defineComponent({
  name: 'Home',
  components: {},
  setup () {
    
    let a = new Date().getTime()
    console.log(window.btoa(escape('time=' + String(a))));
    const message_list = [
      {
        message: '《合同打印》 1.31版本功能更新！',
        description: h(
          'div', 
          {}, 
          [
            h('p',{},`管理员订单操作：“退款”`,)
          ]
        ),
        icon: h(RocketTwoTone, {  }),
        style: {
          width: '500px',
          marginLeft: `${335 - 500}px`,
          borderRadius: '5px',
          padding: '40px',
          borderTop: '10px solid #8bc34a',
          borderRight: '10px solid #4caf50',
          borderBottom: '10px solid #009688',
          borderLeft: '10px solid #c1ef8c',
          backgroundOrigin: 'border-box',
        },
        duration: 8
      }
    ]
    // message_list.forEach((item, index) => {
    //   setTimeout(() => {
    //     notification.open(item)
    //   }, index)
    // })
    // notification.open({
    //   message: '《合同打印》 1.3版本新功能上线啦~',
    //   description:
    //     `如果你想多个合同发往同一个地址,你可以在金慧系统勾选多个合同,点击付费打印，跳转到我们的打印系统页面统一提交一个订单即可
    //     快去试试吧！`,
    //   icon: h(SmileOutlined, { style: 'color: #67c23a' }),
    //   style: {
    //     width: '500px',
    //     marginLeft: `${335 - 500}px`,
    //     border: '2px solid #67c23a',
    //     boxShadow: '0px 0px 5px 0px #67c23a',
    //     borderRadius: '5px'
    //   },
    //   duration: 8
    // });
    // notification.open({
    //   message: '《发票邮寄》 1.0版本功能上线啦~',
    //   description:
    //     `可以从金慧系统的合同管理点开票申请,勾选跳转到发票邮寄页面
    //     快去试试吧！`,
    //   icon: h(SmileOutlined, { style: 'color: #67c23a' }),
    //   style: {
    //     width: '500px',
    //     marginLeft: `${335 - 500}px`,
    //     border: '2px solid #67c23a',
    //     boxShadow: '0px 0px 5px 0px #67c23a',
    //     borderRadius: '5px'
    //   },
    //   duration: 8
    // });
    return {
    }
  }
})

