/* eslint-disable */
import Axios, { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios'
import { message as Message } from 'ant-design-vue'
// 状态码
import { successCode, specialCase, tokenExpires, businessError } from './responseCode'
import signMd5Utils from './signMd5Utils'
// import router from '@/router'
import qs from 'qs'
import store from '@/store'
// import { setToken, getToken, removeToken } from '@/utils/auth'
// import { refreshToken } from '@/api/system/user'
// import NProgress from 'nprogress'
const RESPONSE_STATUS = {
  OK: 200,
  NOT_LOGIN: 401,
  NO_PERMISSON: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  hideLoading?: boolean
}

export interface BaseResponse<T = any> {
  code: number
  data: T
  message: string
}
const baseUrl = process.env.VUE_APP_API_BASE_URL;
console.log('========baseURL',baseUrl)
const service = Axios.create({
  baseURL: baseUrl,
  timeout: 50000
})

service.interceptors.request.use(
  (config: any) => {
    // NProgress.start()

    // config.headers.token = getToken()
    // eslint-disable-next-line no-param-reassign
    // config.data = qs.stringify(config.data) // 转为 formdata 数据格式
    return config
  },
  (error: { message: string }) => {
    Message.error(error.message)
  }
)

// 防止多个接口同时调用刷新接口
let isRefreshing = false
// 需要重试的接口
// let requests: (() => void)[] = []
service.interceptors.response.use(
  async (response: AxiosResponse): Promise<any> => {
    // console.log('======返回值',response)
    const { data } = response
    const { code, message } = data
    // NProgress.done()
    switch (true) {
      case code === tokenExpires: // token失效处理
        Message.warning(message || '业务错误')
        return Promise.reject(data)
      case businessError.includes(code): // 业务错误全局自动提示弹出 返回reject交给后续业务处理
        Message.warning(message || '业务错误')
        return Promise.reject(data)
      case specialCase.includes(code): // 不需要自动弹出提示 直接返回reject
        return Promise.reject(data)
      case code === successCode: // 成功状态吗 返回 resolve
        return Promise.resolve(data)
      default:
        // 其他奇怪的的状态码兜底
        return Promise.reject(data)
    }
  },
  (error: AxiosError) => {
    // NProgress.done()
    Message.destroy()
    const response = Object.assign({}, error.response)
    if (response) {
      switch (response.status) {
        case RESPONSE_STATUS.NOT_FOUND:
          Message.error('没找到接口信息')
          break
        case RESPONSE_STATUS.SERVER_ERROR:
          Message.error('系统异常')
          break
        default:
          Message.error(`连接错误-${response.status}`)
      }
    }
    return Promise.reject(error)
  }
)

const request = <T = any>(config: CustomAxiosRequestConfig): Promise<T> => {
  return new Promise((resolve, reject) => {
    service
      .request<BaseResponse<T>>(config)
    //   .then((res: AxiosResponse) => resolve(res.data))
    // 上面报错，暂时修改为下面的代码
      .then((res: any) => {
        resolve(res.result)
      })
      .catch((err: { message: string }) => reject(err))
  })
}

request.httpAction = <T = any>(url: string, method: Method, params?: any): Promise<T> =>
  request({
    method,
    url,
    data: params,
  })

request.postForm = <T = any>(url: string, params?: any): Promise<T> =>
  request({
    method:'post',
    url,
    data: qs.stringify(params),
  })

request.get = <T = any>(url: string, params?: any): Promise<T> =>
  request({
    method: 'get',
    url,
    params
  })

request.post = <T = any>(url: string, params?: any,responseType?:any): Promise<T> =>
  request({
    method: 'post',
    url,
    data: params,
    responseType:responseType
  })

request.delete = <T = any>(url: string, params?: any): Promise<T> =>
  request({
    method: 'delete',
    url,
    params
  })

request.put = <T = any>(url: string, params?: any): Promise<T> =>
  request({
    method: 'put',
    url,
    data: params
  })

request.patch = <T = any>(url: string, params?: any): Promise<T> =>
  request({
    method: 'patch',
    url,
    data: params
  })

  request.getAction = <T = any>(url: string, params?: any): Promise<T> =>{
    let sign = signMd5Utils.getSign(url, params);
    //将签名和时间戳，添加在请求接口 Header
    let signHeader = {"X-Sign": sign,"X-TIMESTAMP": signMd5Utils.getDateTimeToString()};
   return request({
      method: 'get',
      url,
      data: params,
      headers: signHeader
    })
  }


export default request
