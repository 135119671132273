
const TokenKey = 'refreshToken'
const UserPhone = 'UserPhone'
const UserInfo = 'UserInfo'

export function getToken () {
  return localStorage.getItem(TokenKey)
}
export function getUserPhone() {
  return localStorage.getItem(UserPhone)
}
export function setUserPhone(phone: string) {
  return localStorage.setItem(UserPhone, phone)
}
export function setUserInfo (userInfo: any) {
  return sessionStorage.setItem(UserInfo, JSON.stringify(userInfo))
}
export function getUserInfo () {
  return JSON.parse(sessionStorage.getItem(UserInfo)) as any
}
export function removeUserInfo () {
  return sessionStorage.removeItem(UserInfo)
}
export function removeUserPhone() {
  return localStorage.removeItem(UserPhone)
}

export function setToken (token: string) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken () {
  return localStorage.removeItem(TokenKey)
}
