/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Hr from '@/views/HrUser/Hr.vue'
import CompanySettled from '@/views/companySettled/CompanySettled.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/table',
    children: [
      {
        path: '/table',
        name: 'Table',
        component: () => import('@/views/dashboard/Table.vue')
      },
      {
        path: '/print',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/About.vue')
      },
      {
        path: '/contractPrintOrder',
        name: 'ContractPrintOrder',
        component: () => import(/* webpackChunkName: "about" */ '../views/contractPrintOrder/ContractPrintOrderList.vue')
      },
      
      {
        path: '/invoiceExpressOrder',
        name: 'InvoiceExpressOrder',
        component: () => import(/* webpackChunkName: "about" */ '../views/InvoiceExpressOrder/InvoiceExpressOrder.vue')
      },
      {
        path: '/printException',
        name: 'PrintException',
        component: () => import(/* webpackChunkName: "about" */ '../views/InvoiceExpressOrder/PrintException.vue')
      },
      {
        path: '/companyInvoice',
        name: 'CompanyInvoice',
        component: () => import(/* webpackChunkName: "about" */ '../views/InvoiceExpressOrder/CompanyInvoice.vue')
      },
      {
        path: '/myInvoiceOrder',
        name: 'MyInvoiceOrder',
        component: () => import(/* webpackChunkName: "about" */ '../views/InvoiceExpressOrder/MyInvoiceOrder.vue')
      },
      {
        //分割单页面
        path: '/segmentation',
        name: 'Segmentation',
        component: () => import(/* webpackChunkName: "about" */ '../views/segmentation/segmentation.vue')
      },
      {
        //印章打印-附件
        path:'/sealPrint',
        name:'sealPrint',
        component:() => import(/* webpackChunkName: "sealPrint" */ '../views/sealPrint/sealPrintList.vue')
      },
      {
        path:'/sealPrinting',
        name:'sealPrinting',
        component:() => import(/* webpackChunkName: "sealPrint" */ '../views/sealPrint/sealPrinting.vue')
      },
      
        //分割单页面列表
       { path: '/segmentationList',
        name: 'segmentationList',
        component: () => import(/* webpackChunkName: "about" */ '../views/segmentation/segmentationList.vue')
      }
    ]
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Payment/Payment.vue')
  },
  {
    path: '/publicMark',
    name: 'PublicMark',
    component: () => import('@/views/publicMark/PublicMark.vue'),
  },
  {
    path: '/province',
    name: 'Province',
    component: () => import('@/views/publicMark/Province.vue'),
  },
  {
    path: '/massTexting',
    name: 'MassTexting',
    component: () => import(/* webpackChunkName: "about" */ '@/views/massTexting/MassTexting.vue')
  },
  {
    path: '/companySettled',
    name: 'companySettled',
    component:CompanySettled,
    redirect: '/companySettled/login',
    children: [
      {
        path: 'login',
        name: 'companyLogin',
        component: () => import(/* webpackChunkName: "about" */ '@/views/companySettled/Login.vue')
      },
      {
        path: 'companyInfo',
        name: 'companyInfo',
        component: () => import(/* webpackChunkName: "about" */ '@/views/companySettled/companyInfo.vue')
      }
    ]
    
  },
  {
    path: '/hr',
    name: 'Hr',
    component: Hr,
    redirect: '/hr/login',
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '@/views/HrUser/Login.vue')
      },
      {
        path: 'hrUser',
        name: 'HrUser',
        component: () => import(/* webpackChunkName: "about" */ '@/views/HrUser/HrUser.vue')
      },
      {
        path: 'userExamine',
        name: 'UserExamine',
        component: () => import(/* webpackChunkName: "about" */ '@/views/HrUser/UserExamine/UserExamine.vue')
      },
      {
        path: 'generateCode',
        name: 'GenerateCode',
        component: () => import(/* webpackChunkName: "about" */ '@/views/HrUser/GenerateCode.vue')
      },
      {
        path: 'companyExamine',
        name: 'CompanyExamine',
        component: () => import(/* webpackChunkName: "about" */ '@/views/HrUser/CompanyExamine/CompanyExamine.vue')
      },
      {
        path: 'companyReg',
        name: 'CompanyReg',
        component: () => import(/* webpackChunkName: "about" */ '@/views/HrUser/CompanyReg.vue') //分子公司注册
      },
    ]
    
  },
 
  {
    path: '/url',
    name: 'Url',
    component: () => import(/* webpackChunkName: "about" */ '@/views/UrlEscape.vue')
  },
  {
    path: '/largeScreenBig',
    name: 'LargeScreenBig',
    component: () => import(/* webpackChunkName: "about" */ '@/views/largeScreen/LargeScreenBig.vue'),
  },
  {
    path: '/largeScreen',
    name: 'LargeScreen',
    component: () => import(/* webpackChunkName: "about" */ '@/views/largeScreen/LargeScreen.vue'),
  },
  {
    path: '/dailyReport',
    name: 'DailyReport',
    component: () => import(/* webpackChunkName: "about" */ '@/views/dailyReport/intentRegist.vue'),
  },
  {
    path: '/personage',
    name: 'Personage',
    component: () => import(/* webpackChunkName: "about" */ '@/views/largeScreen/modules/Personage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(),
  // strict: true,
  routes
})
export default router
