import { RouteLocationNormalized, NavigationGuardNext,useRouter } from 'vue-router'
import router from './index'
import store from '../store'
import { getToken } from '@/utils/auth'
import { message } from 'ant-design-vue'
// import { useRouter } from 'vue-router'
// import { addRoutes } from '@/router/addRoutes'
import NProgress from 'nprogress'

const whiteList = ['/login']
/** */
function routerNext(
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  menu: any[],
  replaced = false,
) {
  const firstURL = menu?.[0]?.path

  const isWhitePath = whiteList.indexOf(to.name as string) > -1

  if (menu?.length) {

    if (to.path === '/' || isWhitePath) {
      
      next(firstURL ?? true)
    } else {
      if (replaced) {
        next({ ...to, replace: true })
      } else {
        next()
      }
    }
  } else {

    next()
  }
}
router.beforeEach(async (to: RouteLocationNormalized, _, next: NavigationGuardNext) => {
 
  const token = getToken()

  if (token) {
    next()
    NProgress.done()
    // if (to.path === '/login') {
    //   next({ path: '/' })
    //   NProgress.done()
    // } else {
    //   const { menus } = store.state.user
    //   if (menus?.length || (to.name && to.path !== '/')) {
    //     routerNext(to, next, menus)
    //   } else {
    //     try {
    //       // 获取用户信息、菜单
    //       const menus = await store.dispatch('user/getInfo')
    //       // 添加路由
    //       addRoutes(menus)

    //       routerNext(to, next, menus, true)
    //     } catch (error) {
    //       await store.dispatch('user/logout')
    //       message.error(error || 'Has Error')
    //       next(`/login?redirect=${to.path}`)
    //       NProgress.done()
    //     }
    //   }
    // }
  } else {
    /* has no token*/
    if (to.path.indexOf('/hr/hrUser') !== -1 && to.path !== '/hr/login') {
      next({ path: '/hr/login' })
      NProgress.done()
    } else {
      next()
      NProgress.done()
    }
  }
})
router.afterEach(() => {
  NProgress.done()
})
