import { createStore, ActionContext } from 'vuex'
import { IUserState, PhoneLogin } from '@/store/interface/user'
import { GET_PhoneLogin, GET_logout } from '@/api/hrUser'
import { setUserPhone, setUserInfo, removeUserInfo, removeUserPhone } from "@/utils/auth"

export default createStore({
  state: {
    repositoriesArr: [],
    userInfo: {},
    token: '',
  },
  getters: {
    repositoriesArr:state => state.repositoriesArr,
    userInfo:state => state.userInfo,
    token:state => state.token,
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
    },
    setIUserState: (state, userInfo) => {
      state.userInfo = userInfo
    },
    setRepositories(state, data: []) {
      state.repositoriesArr = data
    }
  },
  actions: {
    async login({ commit }, data: PhoneLogin) {
      console.log(4444);
      
      try {
        const response = await GET_PhoneLogin(data)
        const userInfo = response?.userInfo
        const token = response?.token
        commit('setIUserState', userInfo)
        commit('setToken', token)
        setUserInfo(userInfo)
        window.localStorage.setItem('refreshToken', response.token)
        setUserPhone(userInfo.phone)
        return Promise.resolve(response)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async Logout({ commit }) {
      
      try {
        const response = await GET_logout()
        const userInfo = response
        commit('setIUserState', {})
        localStorage.removeItem('refreshToken')
        removeUserInfo()
        return Promise.resolve(response)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
  modules: {
  }
})
