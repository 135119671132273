
import { defineComponent, provide, readonly, ref, onMounted,nextTick } from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import {getQueryString} from '@/utils/date'
import {setToken} from '@/utils/auth'

import {Base64} from 'js-base64'
// import data from 'china-area-data'

export default defineComponent({
  name: 'App',
  components: {},
  
  setup () {
   provide('reload',reload) //强制刷新页面
    /* readonly包裹后可以在组件内引用时不被改变值。
    否则在组件内可以直接通过title.value=***将值改变，
    包裹后只能通过updataLocation()方法改变 */
    // provide('$pcaa', readonly(data))
    const handleClick = (e: Event) => {
      console.log('click', e)
    }

    document.title = '首辅设计'

    const saveToken = () =>{
      const tokenCode = getQueryString('token');
      if(tokenCode) {
        let token = Base64.decode(tokenCode);
        // let token = tokenCode;
        console.log('========token',token);
        setToken(token);
      }
    }
    let isReload=true
    //强制刷新页面
     function reload(){
      isReload=false
      nextTick(()=>{
        isReload=true
      })
    }
    onMounted(() => {
      window.addEventListener('unload', saveToken() as any);
    })
   
    return {
      handleClick,
      locale: zhCN,
      reload,
      isReload
    }
  }
})
